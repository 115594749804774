import React,{useEffect,useState} from "react";
import Sbi from "./assests/sbi.png";
import Exp from "./assests/Experience.png";
import Advisor from "./assests/About/advisor.jpg";
const AboutSection = () => {
  const [advisorText, setAdvisorText] = useState('advisor');



   useEffect(() => {
      if (process.env.REACT_APP_ADVISOR_SPECIFIER === 'RA') {
        setAdvisorText('Research Analyst');
      }
    }, []);

  return (
    <div
      className="flex flex-col lg:flex-row bg-stone-100"
      section
      id="aboutSection"
    >
      <div className="lg:w-1/3 sm:w-[50%] lg:h-auto">
        <div className="md:w-[440px] h-[570px] sm:w-full sm:h-full bg-cover">
          <img
            src={Advisor}
            alt="About"
            className="object-cover w-full h-full p-4 sm:p-0"
          />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center w-full lg:w-2/3 lg:h-[32rem] px-4 lg:px-0 mx-auto mt-6 lg:mt-0">
        <div className="w-[90%] pb-10 md:pb-0 lg:pb-0 lg:w-full">
          <div className="font-Montserrat text-[16px] text-neutral-500 font-semibold text-center lg:text-left">
            ABOUT ALPHAQUARK
          </div>
          <div className="font-Montserrat text-[#171717] mt-0 font-bold text-2xl sm:text-3xl md:text-[50px] lg:text-4xl text-center lg:text-left">
            AlphaQuark
          </div>

          <p className="font-poppins font-normal mt-4 text-[17px] text-[#7B7B7B] text-left  w-full lg:w-[80%] mx-auto lg:mx-0 lg:text-left">
            AlphaQuark is a firm created by seasoned finance professionals and is a platform dedicated to empowering investors through knowledge and strategy
            With over <br/> <strong>36 years of joint experience</strong> in the Indian stock market, AlphaQuark combines deep technical expertize with solutions for {advisorText}.
          </p>

          <div className="flex flex-row space-x-3 space-y-4 mt-8 items-center justify-center md:flex-row md:space-y-0 md:space-x-8 lg:space-x-14 lg:items-start lg:justify-start">
            <div className="flex items-center space-x-2 md:space-x-3">
              <img src={Exp} alt="Experience Logo" className="w-6 h-8 mt-4 md:mt-0 md:w-8 md:h-10 lg:w-10 lg:h-12" />
              <div className="flex flex-col  mt-4 md:mt-0">
                <div className="text-base md:text-lg lg:text-xl font-bold leading-tight md:leading-7 tracking-tight text-black">
                  36+ years of combined experience
                </div>
                <div className="text-xs md:text-sm lg:text-base leading-snug md:leading-6 text-gray-500 font-semibold">
                  Years of Experience
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;