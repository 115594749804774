import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import formatCurrency from "../../utils/formatCurrency";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import server from "../../utils/serverConfig";
import MPF_1 from "../../assests/mpf_1.png";
import { ChevronRightIcon } from "lucide-react";

const ModalPFCard = ({ userEmail, modelName, repair }) => {
  const navigate = useNavigate();

  const handleCardClick = (modelName) => {
    navigate(
      `/model-portfolio/subscribed/${modelName
        .toLowerCase()
        .replace(/ /g, "_")}`
    );
  };

  const [strategyDetails, setStrategyDetails] = useState(null);
  const hasFetchedStrategy = useRef(false);
  const getStrategyDetails = () => {
    if (modelName && !hasFetchedStrategy.current) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${modelName?.replaceAll(
            /_/g,
            " "
          )}`
        )
        .then((res) => {
          const portfolioData = res.data[0].originalData;
          setStrategyDetails(portfolioData);
          hasFetchedStrategy.current = true;
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    if (modelName && !hasFetchedStrategy.current) {
      getStrategyDetails();
    }
  }, [modelName]);

  const [subscriptionAmount, setSubscrptionAmount] = useState();
  const hasFetchedSubscriptionData = useRef(false);
  const getSubscriptionData = () => {
    let config = {
      method: "get",
      url: `${
        server.server.baseUrl
      }api/model-portfolio-db-update/subscription-raw-amount?email=${encodeURIComponent(
        userEmail
      )}&modelName=${encodeURIComponent(strategyDetails?.model_name)}`,
    };

    axios
      .request(config)
      .then((response) => {
        setSubscrptionAmount(response.data.data);
        hasFetchedSubscriptionData.current = true;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userEmail && strategyDetails && !hasFetchedSubscriptionData.current) {
      getSubscriptionData();
    }
  }, [strategyDetails, userEmail]);

  const net_portfolio_updated = subscriptionAmount?.user_net_pf_model?.sort(
    (a, b) => new Date(b.execDate) - new Date(a.execDate)
  )[0];

  const { getLTPForSymbol } = useWebSocketCurrentPrice(
    net_portfolio_updated?.order_results
  );

  

  const totalInvested =
    net_portfolio_updated?.order_results?.reduce((total, stock) => {
      return total + parseFloat(stock?.averagePrice) * stock?.quantity;
    }, 0) || 0;

  const totalCurrent =
    net_portfolio_updated?.order_results?.reduce((total, stock) => {
      return (
        total + parseFloat(getLTPForSymbol(stock?.symbol)) * stock?.quantity
      );
    }, 0) || 0;

  const totalNetReturns =
    net_portfolio_updated?.order_results?.reduce((total, stock) => {
      return (
        total +
        (parseFloat(getLTPForSymbol(stock?.symbol)) -
          parseFloat(stock.averagePrice)) *
          stock.quantity
      );
    }, 0) || 0;

  return (
    <div
      className="md:bg-[#000000]/10 md:hover:bg-[#000000]/20 w-full md:shadow-[3px_5px_20px_0px_rgba(0,0,0,0.06)] px-0 md:px-8 lg:px-10 cursor-pointer
    "
      onClick={() => handleCardClick(modelName)}
    >
      <div className="hidden lg:grid md:grid grid-cols-7 gap-4 overflow-y-scroll no-scrollbar py-5">
        <div className="flex items-center space-x-4 col-span-2">
          <img src={MPF_1} alt="Portfolio" className="w-12 h-10" />
          <div className="flex flex-col">
            <div className="text-white font-medium font-poppins text-sm sm:text-base capitalize">
              {modelName}
            </div>
            {repair && (
              <div className="w-[55px] flex items-center justify-center font-medium font-poppins rounded-[3px] bg-[#DE8846] text-[10px] text-[#ffffff]">
                Repair
                <ChevronRightIcon className="text-white h-3 w-3" />
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-white lg:pl-10 font-medium font-poppins text-sm sm:text-base">
            ₹{formatCurrency(parseFloat(totalInvested.toFixed(2)))}
          </p>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-white lg:pl-9 font-medium font-poppins text-sm sm:text-base">
            ₹{formatCurrency(parseFloat(totalCurrent.toFixed(2)))}
          </p>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-white lg:pl-20 font-medium font-poppins text-sm sm:text-base">
            {totalNetReturns > 0
              ? `+₹${formatCurrency(Math.abs(totalNetReturns.toFixed(2)))}`
              : totalNetReturns < 0
              ? `-₹${formatCurrency(Math.abs(totalNetReturns.toFixed(2)))}`
              : `₹${formatCurrency(Math.abs(totalNetReturns.toFixed(2)))}`}
          </p>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-[#7CFA8B] lg:pl-12 font-semibold font-poppins text-sm sm:text-base">
            TBC
          </p>
        </div>
        <div className="flex items-center  lg:pl-12">
          <button
            className="text-white border border-[#ffffff]/40 rounded-[4px] px-4 py-1 font-medium font-poppins text-sm sm:text-base cursor-pointer"
            onClick={() => handleCardClick(modelName)}
          >
            View
          </button>
        </div>
      </div>

      <div
        className="md:hidden grid grid-cols-2 py-2 px-3  w-full "
        onClick={() => handleCardClick(modelName)}
      >
        <div className="flex items-center ">
          <div className="text-white font-poppins text-sm capitalize">
            <div className="flex space-x-1 col-span-2 ">
              <img src={MPF_1} alt="Portfolio" className="w-[40px] h-[38px]" />
              <div className="flex flex-col pl-1">
                <span className="text-[13px] text-white font-normal">
                  {modelName}
                </span>
                {repair && (
                  <div className="w-[55px] flex items-center justify-center font-medium font-poppins rounded-[3px] bg-[#DE8846] text-[10px] text-[#ffffff]">
                    Repair
                    <ChevronRightIcon className="text-white h-3 w-3" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-end pt-1.5 leading-5">
            <span className="text-[#57FB6D] font-medium font-poppins text-[13px] ">
              ₹16,00,000
            </span>
            <p className="text-[#57FB6D] font-medium font-poppins text-[13px]">
              (+120%)
            </p>
          </div>

          <div className="flex  justify-end leading-6">
            <span className="text-[#FFFFFF] font-normal font-poppins text-[13px] ">
              ₹7,50,000
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPFCard;
